.payment_method_list_item_icon {
  color: var(--color-gray4);
  font-size: 21px;
  line-height: 1;
}

.payment_method_list_item_icon.img {
  width: 32px;
}

.ns_com_payment_methods_dialog_list_item_main {
  cursor: pointer;
}

.ns_com_payment_methods_dialog_list_item_main .list_item_drag_btn_wrapper {
  cursor: move;
}

.ns_com_payment_methods_dialog_list_item_main .test_button .Polaris-Button--plain svg {
  fill: var(--p-text);
}

@media screen and (max-width: 768px) {
  .ns_com_payment_methods_dialog_list_item_main {
    box-shadow: none;
    border-radius: 0;
  }
}

.ns_com_payment_methods_lists_main .empty {
  width: max-content;
  padding: var(--spacing-l);
  color: var(--color-gray4);
  font-size: var(--font-size3);
  text-align: center;
  cursor: pointer;
  background: #e8e8e880;
  border-radius: 8px;
  margin: 80px auto;
  font-weight: 500;
  line-height: 1.4;
}

.ns_com_payment_methods_lists_main .empty .empty_icon {
  margin-bottom: var(--spacing-m);
}

@media screen and (max-width: 768px) {
  .ns_com_payment_methods_lists_main .payment_method_list {
    gap: var(--spacing-m);
  }

  .ns_com_payment_methods_lists_main .empty {
    z-index: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*# sourceMappingURL=index.055b3f09.css.map */
